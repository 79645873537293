<template>
  <VCard
    ref="scormWrapper"
    class="step-scorm"
  >
    <header class="step-header">
      <StepToolbar :step="step" />

      <VCardTitle class="lesson-title">
        {{ step.lesson_title }}
      </VCardTitle>
      <VAlert
        v-if="step.is_summative && isAvailableSummativeRaw"
        text
        type="error"
      >
        {{ isAvailableSummativeRaw }}
      </VAlert>
    </header>
    <VDivider />

    <VCardText
      v-if="description"
      class="step__body"
    >
      <VCard
        v-if="step.is_skipped || !step.is_completed || !step.settings.hide_accepted_content"
        flat
      >
        <VToolbar
          v-if="hasFullscreen"
          dense
        >
          <VSpacer />
          <VBtn
            icon
            title="Развернуть на весь экран"
            @click.prevent="fullscreen"
          >
            <VIcon>fullscreen</VIcon>
          </VBtn>
        </VToolbar>
        <VResponsive :aspect-ratio="16/9">
          <div v-html="description" />
        </VResponsive>
      </VCard>
    </VCardText>
  </VCard>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { Scorm12API, Scorm2004API } from '@/utils/scorm'
import trainingStep from '@/mixins/trainingStep'
import StepToolbar from '@components/StepToolbar.vue'
import * as getters from '@/store/getters/types'
import * as actions from '@/store/actions/types'
import { fullscreen } from '@/utils/common'

export default {
  name: 'TrainingScorm',

  components: {
    StepToolbar
  },

  mixins: [trainingStep],

  data () {
    return {
      scorm12: null,
      scorm2004: null
    }
  },

  computed: {
    ...mapGetters({
      account: getters.ACCOUNT,
      session: getters.SESSION,
      step: getters.STEP,
      lesson: getters.LESSON,
      permissions: getters.PERMISSIONS
    }),

    description () {
      if (!this.lesson?.description) { return '' }

      const wrapper = document.createElement('div')
      wrapper.innerHTML = this.lesson.description
      return wrapper.querySelector('iframe').outerHTML
    },

    stateKey () {
      if (this.account.id && this.session.id && this.step.id) {
        return `/users/${this.account.id}/sessions/${this.session.id}/steps/${this.step.id}`
      }

      return ''
    },

    hasFullscreen () {
      const fullscreenEnabled = document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullscreenEnabled || document.msFullscreenEnabled

      return Boolean(fullscreenEnabled)
    }
  },

  created () {
    this.register()
  },

  beforeDestroy () {
    this.unregister()
  },

  methods: {
    ...mapActions({
      saveScorm: actions.SAVE_SCORM
    }),

    fullscreen () {
      const iframe = this.$refs.scormWrapper.$el.querySelector('iframe')
      if (iframe) {
        fullscreen(iframe)
      }
    },

    lmsInitialize (data) {
      // Загрузим состояние тренажёра из localStorage.
      let d
      try {
        d = JSON.parse(window.localStorage.getItem(this.stateKey))
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
      }

      if (d && d instanceof Object) {
        return Object.assign({}, data, d)
      }

      return data
    },

    lmsSetValue (data) {
      // Сохраним состояние тренажёра в localStorage.
      if (window.localStorage) {
        window.localStorage.setItem(this.stateKey, JSON.stringify(data))
      }
    },

    lmsCommit (data) {
      const status = (data['cmi.success_status'] || data['cmi.core.lesson_status'] || '').toLowerCase()
      // eslint-disable-next-line
      console.log('scorm: status: %s', status)

      const completeness = (data['cmi.completion_status'] || '').toLowerCase()
      // eslint-disable-next-line
      console.log('scorm: completeness: %s', completeness)

      switch (status) {
        case 'complete':
        case 'completed':
        case 'finish':
        case 'finished':
        case 'pass':
        case 'passed': {
          if (this.step.is_accepted) {
            return
          }

          // eslint-disable-next-line
          console.log('scorm: обучение успешно завершено')

          // Приведём полученный балл к интервалу [0..100], т. е. к проценту выполнения.
          const grade = Math.max(0, parseInt(data['cmi.score.raw'] || data['cmi.core.score.raw']) || 0)
          const min = Math.max(0, parseInt(data['cmi.score.min'] || data['cmi.core.score.min']) || 0)
          const max = Math.max(0, parseInt(data['cmi.score.max'] || data['cmi.core.score.max']) || 0)
          let gradeRatio = (grade > 0) ? 100 : 0
          if (max > min) {
            gradeRatio = Math.max(0, Math.min(100, Math.round(100.0 * ((1.0 * grade) / (max - min)))))
          }

          const grades = {
            grade: gradeRatio,
            raw: grade,
            min,
            max
          }

          this.saveScorm({
            stepID: this.step.id,
            grades,
            accepted: true
          })
          break
        }

        case 'unknown': {
          if (this.step.is_accepted) {
            return
          }

          // eslint-disable-next-line
          console.log('scorm: похоже, оценки нет')

          if (completeness === 'complete' || completeness === 'completed') {
            const progress = data['cmi.progress_measure']
            if (progress === undefined || progress === '' || (parseFloat(progress) || 0.0) > 0.9999) {
              // eslint-disable-next-line
              console.log('scorm: обучение успешно завершено')
              this.saveScorm({
                stepID: this.step.id,
                grades: { grade: 100 },
                accepted: true
              })
            }
          }
          break
        }

        default: {
          break
        }
      }
    },

    register () {
      this.scorm12 = new Scorm12API(this.account, {
        onLmsInitialize: this.lmsInitialize,
        onLmsSetValue: this.lmsSetValue,
        onLmsCommit: this.lmsCommit
      })

      try {
        this.scorm12.attach(window)
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
      }

      this.scorm2004 = new Scorm2004API(this.account, {
        onLmsInitialize: this.lmsInitialize,
        onLmsSetValue: this.lmsSetValue,
        onLmsCommit: this.lmsCommit
      })

      try {
        this.scorm2004.attach(window)
      } catch (e) {
        // eslint-disable-next-line
        console.log(e)
      }
    },

    unregister () {
      this.scorm12.destroy(window)
      this.scorm2004.destroy(window)
    }
  }
}
</script>
